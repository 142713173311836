import React from 'react';
import { PageProps } from 'gatsby';

import DefaultLayout from '@/layouts/default';

const NotFound: React.FC<PageProps> = () => (
  <DefaultLayout>
    <main>
      <p>Sorry, page not found!</p>
    </main>
  </DefaultLayout>
);

export default NotFound;
