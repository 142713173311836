import '../global-styles';
import React, { FC, ReactNode } from 'react';
import * as styles from './index.module.css';

export interface DefaultLayoutProperties {
  children: ReactNode;
}

type Props = DefaultLayoutProperties;

const DefaultLayout: FC<Props> = ({ children }) => (
  <div className={styles.defaultLayout}>{children}</div>
);

export default DefaultLayout;
